import * as React from "react";
import "./SwimTipsList.scss";

const SwimTipsList = ({ swimTips }) => {
  return (
    <div className="swim-tips-list row">
      {swimTips.map((tip, index) => {
        return (
          <div
            className="col-lg-4 swim-tip-item d-flex align-items-center justify-content-center flex-column"
            key={index}
          >
            <img
              src={tip.resolvedImageUrl}
              alt={tip?.description[0]}
              className="cursor-pointer"
              tabIndex="0"
            />
            <div className="tip-title mb-4">{tip.title}</div>
            <div className="tip-desc text-center">{tip.description}</div>
            <div className="bar mt-3"></div>
          </div>
        );
      })}
    </div>
  );
};
export default SwimTipsList;
