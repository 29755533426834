import React, { useEffect, useState } from "react";
import "./BannersSlider.scss";
import axios from "../../http/axios";
import { getImageUrl, isBrowser } from "../../services/Utils";

import {
    MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem,
    MDBCarouselElement,
    MDBCarouselCaption,
} from "mdb-react-ui-kit";

const videoExtensions = [
    "mp3",
    "mp4",
    "webm",
    "mkv",
    "flv",
    "vob",
    "ogv",
    "drc",
    "gif",
    "gifv",
    "mng",
    "avi",
    "MTS",
    "M2TS",
    "TS",
    "mov",
    "qt",
    "wmv",
    "yuv",
    "rm",
    "rmvb",
    "viv",
    "asf",
    "amv",
    "m4p",
    "m4v",
    "mpg",
    "mp2",
    "mpeg",
    "mpe",
    "mpv",
    "mpg",
    "mpeg",
    "m2v",
    "m4v",
    "svi",
    "3gp",
    "3g2",
    "mxf",
    "roq",
    "nsv",
    "flv",
    "f4v",
    "f4p",
    "f4a",
    "f4b",
];

const BannersSlider = () => {
    const [items, setItem] = useState([]);
    useEffect(() => {
        axios
            .get("/slides/page/Homepage")
            .then((r) => {
                setItem(r.data);
                setTimeout(() => {
                    if (isBrowser()) {
                        const indicators = document.querySelectorAll(
                            ".carousel-indicators li"
                        );
                        if (indicators) {
                            for (let i = 0, element; (element = indicators[i]); i++) {
                                element.setAttribute("role", "button");
                                element.setAttribute("aria-label", `Change slide ${i + 1}`);
                                element.setAttribute("tabindex", "0");
                            }
                        }

                        const nextBtn = document.querySelector(
                            ".carousel-control-next-icon"
                        );
                        const prevBtn = document.querySelector(
                            ".carousel-control-prev-icon"
                        );

                        if (nextBtn) {
                            nextBtn.setAttribute("role", "button");
                            nextBtn.setAttribute("tabindex", "0");
                        }
                        if (prevBtn) {
                            prevBtn.setAttribute("role", "button");
                            prevBtn.setAttribute("tabindex", "0");
                        }
                    }
                }, 1000);
            })
            .catch((err) => {
                console.log("err", err);
            });
    }, []);

    return (
        <div className="slide-block">
            {items?.length && (
                <MDBCarousel
                    showControls={true}
                    showIndicators={true}
                    length={items.length}
                >
                    <MDBCarouselInner>
                        {items &&
                            items.map((item, idx) => (
                                <MDBCarouselItem
                                    key={item.id}
                                    id={item.id + "_carousel"}
                                    className={idx === 0 ? "active" : ""}
                                >
                                    {videoExtensions.includes(item?.url.split(".").pop()) ? (
                                        <MDBCarouselElement src={getImageUrl(item?.url)} alt={item.title ? item.title[0] : `Motivational quote ${idx}`} />
                                    ) : (
                                        <MDBCarouselElement src={getImageUrl(item?.url)} alt={item.title ? item.title[0] : `Motivational quote ${idx}`} />
                                    )}

                                    <MDBCarouselCaption>
                                        <div className="title-description-wrapper">
                                            <div
                                                role="heading"
                                                style={{ color: item?.textColor }}
                                                className="title mb-3 slider-text slider-title"
                                            >
                                                {item?.title}
                                            </div>
                                            {item?.description && (
                                                <div
                                                    style={{ color: item?.textColor }}
                                                    className="description slider-text"
                                                    role="heading"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item?.description,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                            ))}
                    </MDBCarouselInner>
                </MDBCarousel>
            )}
        </div>
    );
};

export default BannersSlider;
