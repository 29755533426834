import React, { useEffect, useState } from "react";
import "./BorderedBtn.scss";
import { Link } from "gatsby";

const BorderedBtn = ({
  text,
  innerColor,
  sideLinesNeeded,
  directLink,
  additionalButtons,
  ...props
}) => {
  const [btnSideLines, setBtnSideLines] = useState(false);

  useEffect(() => {
    setBtnSideLines(sideLinesNeeded);
  }, [sideLinesNeeded]);

  const sideLines = btnSideLines ? "side-lines" : "";

  return (
    <div className={`bordered-btn ${sideLines} text-center cursor-pointer`}>
      {sideLinesNeeded && <div className="left-bar"></div>}
      <div className="d-flex flex-wrap align-items-center additional-buttons-wrapper">
        {directLink ? (
          <>
            <Link to={directLink}>
              <button className={`${innerColor}`}>{text}</button>
            </Link>
          </>
        ) : (
          <button className={`${innerColor}`}>{text}</button>
        )}
        {additionalButtons && additionalButtons.length > 0 && (
          <>
            {additionalButtons.map((button) => {
              return (
                <Link to={button.link ? button.link : "/"} key={button.text}>
                  <button className={`${button.innerColor}`}>
                    {button.text}
                  </button>
                </Link>
              );
            })}
          </>
        )}
      </div>
      {sideLinesNeeded && <div className="right-bar"></div>}
    </div>
  );
};

export default BorderedBtn;
