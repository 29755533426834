import * as React from "react";
import "./UpcomingSession.scss";

const UpcomingSession = ({ data }) => {
  return (
    <div className="upcoming-session">
      <div className="row my-3">
        {data.token !== "philosophy" && (
          <div className="col-lg-12 title text-center">{data?.title}</div>
        )}
      </div>
      <div className="row">
        <div
          className="col-lg-12 text-center"
          dangerouslySetInnerHTML={{ __html: data?.description }}
        ></div>
      </div>
    </div>
  );
};

export default UpcomingSession;
