import React, { useState, useEffect } from "react";
import Seo from "../components/seo/seo";
import { Link } from "gatsby";
import "../scss/index.scss";
import UpcomingSession from "../shared/upcoming-session/UpcomingSession";
import BorderedBtn from "../shared/bordered-btn/BorderedBtn";
import SwimTipsList from "../shared/swim-tips-list/SwimTipsList";
import axios from "../http/axios";
import BannersSlider from "../shared/banners-slider/BannersSlider";

const IndexPage = () => {
  const [swimTips, setSwimTips] = useState([]);
  const [upcomingSessionData, setUpcomingSessionData] = useState([]);
  const [philosophy, setPhilosophy] = useState([]);

  useEffect(() => {
    axios.get(`/swim-tips`).then((res) => {
      setSwimTips(res.data);
    });
    axios.get(`/content/upcomingSession`).then((res) => {
      setUpcomingSessionData(res.data);
    });
    axios.get(`/content/philosophy`).then((res) => {
      setPhilosophy(res.data);
    });
  }, []);

  const additionalNavButtons = [
    {
      text: "Programs",
      link: "/programs",
      innerColor: "blue",
    },
  ];

  return (
    <div className="index-page">
      <Seo title="Home Page -" />
      <BannersSlider />
      <div className="container">
        <div className="row">
          <h1 className="col-12 d-flex justify-content-center mt-6">
            UPCOMING SESSIONS
          </h1>
          <UpcomingSession data={upcomingSessionData} />
        </div>
        <div className="row mb-6">
          <div className="col">
            <Link to="/reserve">
              <BorderedBtn
                text="Reserve Your Class"
                innerColor="blue"
                sideLinesNeeded={true}
              />
            </Link>
          </div>
        </div>
        <div className="row mb-6">
          <div className="col">
            <BorderedBtn
              text="FAQ"
              directLink="/faq"
              innerColor="blue"
              sideLinesNeeded={false}
              additionalButtons={additionalNavButtons}
            />
          </div>
        </div>
      </div>
      <div className="fixed-bg-image-block pb-5 mb-5">
        <div className="content-box container">
          <div className="row">
            <h2 className="col-12 d-flex justify-content-center mt-5 text-uppercase">
              {philosophy.title}
            </h2>
            <UpcomingSession data={philosophy} />
          </div>
          <div className="row">
            <div className="col">
              <Link to="/about">
                <BorderedBtn
                  text="Learn More About Brian"
                  innerColor="white"
                  sideLinesNeeded={true}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-6">
        <SwimTipsList swimTips={swimTips} />
      </div>
    </div>
  );
};

export default IndexPage;
